import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { MDBContainer } from 'mdbreact'
import Layout from '../../../components/layout'
import Hero from '../../../components/heroSubpage'
import SEO from '../../../components/seo'
import get from 'lodash/get'
import { kebabCase } from 'lodash'

const TagsPage = (props) => {
  const posts = get(props, 'data.allMarkdownRemark')
  const heroImage = get(props, 'data.heroImage')

  return (
  <Layout>
    <div id="sub-page">
      <Hero
        class="intro-50"
        image={ heroImage.childImageSharp.gatsbyImageData }
        title="UNICOM Government blog"
        subtitle="Technology updates and insights."
        type="blog"
      />        
      
      <main>
        <section id="main" className="bg-white">
          <MDBContainer>
        <div className="columns">
          <div className="column is-10 is-offset-1" style={{ marginBottom: "6rem" }} >
            <p className="font-alt font-w-300 letter-spacing-1 text-medium"><Link to="/blog/" className="effect">Blog</Link> / Tags</p>
            <ul className="tag-list pt-3">
              {posts.group.map((tag) => (
                <li key={tag.fieldValue} className="py-2">
                  <Link to={`/blog/tags/${kebabCase(tag.fieldValue)}/`} className="effect">
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </MDBContainer>
    </section>
    </main>
    </div>
  </Layout>
)}

export default TagsPage

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title={`UNICOM Government tags`}
      description={`Drill down into UNICOM Government blogs by blog category/tag`}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const tagPageQuery = graphql`
  query TagsListQuery {
    allMarkdownRemark
    {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    heroImage: file(name: { eq: "unicom-government-blog-banner" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }  
  }
`;
